.new-button-wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
}

.item-description-icon {
  color: #0a0523;
}

.avatar-active {
  background-color: #FFD54F;
}

