.mt {
  &-1 {
    margin-top: 1em;
  }
  &-2 {
    margin-top: 2em;
  }
  &-3 {
    margin-top: 3em;
  }
  &-4 {
    margin-top: 4em;
  }
  &-5 {
    margin-top: 5em;
  }
}

.mb {
  &-1 {
    margin-bottom: 1em;
  }
  &-2 {
    margin-bottom: 2em;
  }
  &-3 {
    margin-bottom: 3em;
  }
  &-4 {
    margin-bottom: 4em;
  }
  &-5 {
    margin-bottom: 5em;
  }
}

.mr {
  &-1 {
    margin-right: 1em;
  }
  &-2 {
    margin-right: 2em;
  }
  &-3 {
    margin-right: 3em;
  }
  &-4 {
    margin-right: 4em;
  }
  &-5 {
    margin-right: 5em;
  }
}

.ml {
  &-1 {
    margin-left: 1em;
  }
  &-2 {
    margin-left: 2em;
  }
  &-3 {
    margin-left: 3em;
  }
  &-4 {
    margin-left: 4em;
  }
  &-5 {
    margin-left: 5em;
  }
}
