.form-control {
  display: block;
  width: 100%;
}

.address-item {
  cursor: pointer;

  &:hover {
    background-color: #cccccc;
  }

  &.active {
    background-color: #1677ff;

    & .ant-list-item-meta-title, & .ant-list-item-meta-description {
      color: #FFFFFF !important;
    }
  }
}

.ant-list-item-meta-title {
  margin-top: 0;
}
