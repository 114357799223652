.steps-wrapper {
  display: block;
  width: 100%;
}

.steps-content {
  display: block;
  padding: 2rem;

  @media only screen and (max-width: 550px) {
    padding: 0;
  }
}

.steps-action {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
